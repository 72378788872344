/* volume2/docker/home/node/app-v2/client/src/styles/UserProfile.css */

.user-profile {
  max-width: 935px;
  margin: 0 auto;
  padding: 30px 20px;
}

.profile-header {
  display: flex;
  margin-bottom: 44px;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 30px;
}

.profile-info {
  flex: 1;
}

.profile-username {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-username h2 {
  font-size: 28px;
  font-weight: 300;
  margin-right: 20px;
}

.follow-button, .edit-profile-button {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 9px;
  border-radius: 4px;
  cursor: pointer;
}

.follow-button {
  background-color: #0095f6;
  color: white;
  border: none;
}

.follow-button.following {
  background-color: #ffffff;
  color: #262626;
  border: 1px solid #dbdbdb;
}

.edit-profile-button {
  background-color: transparent;
  border: 1px solid #dbdbdb;
  color: #262626;
}

.profile-stats {
  display: flex;
  margin-bottom: 20px;
}

.profile-stats span {
  margin-right: 40px;
  font-size: 16px;
}

.profile-bio {
  font-size: 16px;
  line-height: 1.5;
}

.profile-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
}

.profile-post {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.profile-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-post:hover .post-overlay {
  opacity: 1;
}

.post-overlay span {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
}

@media (max-width: 735px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profile-info {
    text-align: center;
  }

  .profile-username {
    flex-direction: column;
  }

  .profile-username h2 {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile-stats {
    justify-content: center;
  }

  .profile-posts {
    gap: 3px;
  }
}