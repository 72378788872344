.activity-feed {
    max-width: 614px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .activity-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
  }
  
  .activity-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #dbdbdb;
  }
  
  .activity-content {
    flex-grow: 1;
  }
  
  .activity-username {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .activity-action {
    color: #8e8e8e;
  }
  
  .activity-timestamp {
    font-size: 0.8em;
    color: #8e8e8e;
  }