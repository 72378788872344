.explore-page {
  padding-bottom: 60px;
  height: 100%;
  overflow-y: auto;
}

.explore-content {
  max-width: 935px;
  margin: 0 auto;
  padding: 20px;
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
}

.search-bar select {
  padding: 10px;
  font-size: 16px;
  margin-left: 10px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.search-results {
  margin-top: 30px;
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.users-results, .posts-results {
  flex: 1;
  min-width: 300px;
}

.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
}

.user-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.user-image-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.username {
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.popular-posts {
  margin-top: 40px;
}

.post-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
}

.post-preview {
  position: relative;
  cursor: pointer;
}

.media-preview {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

.media-preview img,
.media-preview video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-preview {
  position: relative;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
}

.video-icon svg {
  color: white;
}

.post-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.post-preview:hover .post-overlay {
  opacity: 1;
}

.post-stats {
  color: white;
  font-weight: bold;
  display: flex;
}

.post-stats > div {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.post-stats svg {
  margin-right: 5px;
}

.suggested-users {
  margin-top: 30px;
  margin-bottom: 20px;
}

.suggested-users h2 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

.suggested-users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.suggested-user {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.suggested-user:hover {
  background-color: #f0f0f0;
}

.suggested-user-image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;
}

.suggested-user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.suggested-user-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.suggested-username {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggested-followers {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 모달 관련 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  max-width: 935px;
  max-height: calc(100% - 120px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-inner {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.modal-media {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.modal-media img,
.modal-media video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modal-info {
  width: 300px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modal-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.modal-username {
  font-weight: bold;
}

.modal-caption {
  margin-bottom: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  z-index: 1001;
}

.post-actions {
  display: flex;
  padding: 6px 0;
}

.post-actions-left {
  display: flex;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
}

.action-button:first-child {
  padding-left: 0;
}

.action-button svg {
  width: 24px;
  height: 24px;
}

.action-button.liked svg {
  fill: #ed4956;
  color: #ed4956;
}

.post-info {
  margin-top: 10px;
}

.post-likes, .post-comments-count {
  font-weight: bold;
  margin-bottom: 5px;
}

body.modal-open {
  overflow: hidden;
}

@media (max-width: 935px) {
  .post-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: calc(100% - 110px);
    max-height: none;
    border-radius: 0;
    margin: 55px 0;
  }

  .modal-inner {
    flex-direction: column;
  }

  .modal-media {
    height: 60%;
  }

  .modal-info {
    width: 100%;
    height: 40%;
  }

  .close-button {
    top: 15px;
    right: 15px;
  }
}

@media (max-width: 640px) {
  .post-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }

  .explore-content {
    padding: 0;
  }
  
  .user-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
  
  .user-image-container {
    width: 60px;
    height: 60px;
  }
  
  .username {
    font-size: 12px;
  }

  .suggested-users-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .suggested-user-image-container {
    width: 40px;
    height: 40px;
  }
  
  .suggested-username {
    font-size: 13px;
  }
  
  .suggested-followers {
    font-size: 11px;
  }
}