.home-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 614px;
  margin: 0 auto;
  padding: 20px 0;
}

@media (max-width: 614px) {
  .home-feed {
    width: 100%;
    padding: 5px 5px;
    box-sizing: border-box;
  }

  .post {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.load-more {
  background-color: #0095f6;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.load-more:hover {
  background-color: #0081d6;
}

/* Post 컴포넌트 관련 스타일 */
.post {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 60px;
}

.media-slider {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* 1:1 비율 유지 */
  overflow: hidden;
  background-color: #000;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.slide.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img, .slide video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.play-button svg {
  fill: white;
  width: 24px;
  height: 24px;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0,0,0,0.3);
  border: none;
  z-index: 5;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.slide-counter {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  border-radius: 0 0 0 5px;
  z-index: 5;
}