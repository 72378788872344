.create-post {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.create-post h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #262626;
}

.create-post form {
  display: flex;
  flex-direction: column;
}

.media-upload {
  margin-bottom: 20px;
}

.media-input-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: #0095f6;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.media-input-label:hover {
  background-color: #0081d6;
}

.media-input {
  display: none;
}

.media-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.media-item {
  position: relative;
  width: calc(33.333% - 10px);
}

.preview-image,
.preview-video {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.remove-media {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0,0,0,0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-post textarea,
.create-post input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 14px;
}

.create-post textarea {
  height: 100px;
  resize: vertical;
}

.create-post button[type="submit"] {
  padding: 10px 15px;
  background-color: #0095f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.create-post button[type="submit"]:hover {
  background-color: #0081d6;
}

@media (max-width: 600px) {
  .create-post {
    padding: 15px;
  }

  .media-item {
    width: calc(50% - 5px);
  }
}