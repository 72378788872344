.post {
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 60px;
  max-width: 614px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 614px) {
  .post {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
  }
}

.post-header {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid #efefef;
}

.post-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-username {
  font-weight: 600;
  color: #262626;
}

.media-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: #000;
  overflow: hidden;
}

.media-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-content img,
.media-content video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  z-index: 10;
}

.play-button svg {
  width: 24px;
  height: 24px;
  color: white;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.prev { left: 10px; }
.next { right: 10px; }

.slide-counter {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
}

.post-actions {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.post-actions-left, .post-actions-right {
  display: flex;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
}

.action-button:last-child {
  margin-right: 0;
}

.action-button svg {
  width: 24px;
  height: 24px;
}

.post-info {
  padding: 0 16px;
}

.post-likes {
  font-weight: 600;
  margin-bottom: 8px;
}

.post-caption {
  margin-bottom: 8px;
}

.post-hashtags {
  margin-bottom: 8px;
}

.hashtag {
  color: #00376b;
  margin-right: 4px;
}

.view-comments-button {
  background: none;
  border: none;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0;
  margin-bottom: 8px;
}

.post-comments {
  padding: 0 16px;
  max-height: 200px;
  overflow-y: auto;
}

.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.comment-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-content {
  flex-grow: 1;
}

.comment-username {
  font-weight: 600;
  margin-right: 4px;
}

.comment-text {
  word-break: break-word;
}

.delete-comment {
  background: none;
  border: none;
  color: #8e8e8e;
  cursor: pointer;
  font-size: 12px;
  margin-left: 8px;
}

.comment-form {
  display: flex;
  border-top: 1px solid #efefef;
  padding: 16px;
}

.comment-form input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
}

.comment-form button {
  background: none;
  border: none;
  color: #0095f6;
  font-weight: 600;
  cursor: pointer;
}

.comment-form button:disabled {
  opacity: 0.3;
}