/* volume2/docker/home/node/app-v2/client/src/styles/EditProfile.css */

.edit-profile {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.edit-profile h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.edit-profile form {
  display: flex;
  flex-direction: column;
}

.edit-profile form > div {
  margin-bottom: 15px;
}

.edit-profile label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-profile input,
.edit-profile textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.edit-profile textarea {
  height: 100px;
  resize: vertical;
}

.edit-profile button {
  background-color: #0095f6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
}

.edit-profile button:hover {
  background-color: #0081d6;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.edit-profile {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.profile-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.profile-image-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.edit-profile form > div {
  margin-bottom: 15px;
}

.edit-profile label {
  display: block;
  margin-bottom: 5px;
}

.edit-profile input,
.edit-profile textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-profile textarea {
  height: 100px;
  resize: vertical;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-group button[type="submit"] {
  background-color: #0095f6;
  color: white;
}

.button-group button[type="button"] {
  background-color: #dbdbdb;
  color: #262626;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}