body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overflow-x: hidden; /* 가로 스크롤 방지 */
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* 가로 스크롤 방지 */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden; /* 가로 스크롤 방지 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.app-header {
  background-color: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #262626;
}

.header-actions {
  display: flex;
  align-items: center;
}

.header-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 22px;
  padding: 0;
}

.header-button:first-child {
  margin-left: 0;
}

.app-main {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-top: 50px; /* 헤더의 높이만큼 상단 패딩 추가 */
  padding-bottom: 70px; /* 하단 네비게이션 바의 높이만큼 하단 패딩 추가 */
  width: 100%;
  box-sizing: border-box;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  padding: 10px 0;
  z-index: 1000;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
}

.bottom-nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.bottom-nav li {
  flex: 1;
  text-align: center;
}

.bottom-nav a, .bottom-nav button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #262626;
  text-decoration: none;
}

.bottom-nav svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 640px) {
  .app-container {
    width: 100vw; /* 뷰포트 너비로 설정 */
    max-width: 100vw;
  }

  .header-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px; /* 좌우 패딩 추가 */
  }

  .header-actions {
    margin-top: 0;
  }

  .logo {
    font-size: 20px;
  }

  .header-button {
    margin-left: 15px;
  }

  .app-main {
    padding-top: 50px;
    padding-bottom: 70px;  /* 50px에서 70px로 증가 */
    width: 100%;
  }

  .bottom-nav {
    height: 50px;
  }
}

/* 터치 동작 최적화 */
* {
  -webkit-tap-highlight-color: transparent;
}

/* 선택 방지 */
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}