.direct-message {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .message-list {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 400px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .message.sent {
    background-color: #dcf8c6;
    align-self: flex-end;
  }
  
  .message.received {
    background-color: #f1f0f0;
    align-self: flex-start;
  }
  
  .message-input {
    display: flex;
  }
  
  .message-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  
  .message-input button {
    padding: 10px 20px;
    background-color: #0095f6;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }