.edit-post {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-post h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.edit-post form {
  display: flex;
  flex-direction: column;
}

.edit-post textarea,
.edit-post input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.edit-post textarea {
  height: 100px;
  resize: vertical;
}

.edit-post button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-post button:hover {
  background-color: #0077c8;
}

.media-preview-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.media-preview {
  display: flex;
  gap: 10px;
}

.media-item {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
}

.media-item img,
.media-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-post .error-message {
  color: #ff3860;
  margin-bottom: 15px;
}

.edit-post .success-message {
  color: #23d160;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .edit-post {
    padding: 15px;
  }

  .edit-post h2 {
    font-size: 20px;
  }

  .edit-post textarea,
  .edit-post input[type="text"] {
    font-size: 14px;
  }

  .edit-post button {
    font-size: 14px;
  }

  .media-item {
    width: 150px;
    height: 150px;
  }
}